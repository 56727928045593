import React from "react";

import { Button, Container, Row, Col } from "reactstrap";

// core components
import SolNavbar from "components/Navbars/SolNavbar.js";
import SolFooter from "components/Footers/SolFooter.js";

function Thanks() {
  return (
    <>
      <SolNavbar />
      <div style={{ color: "black", paddingTop: 100, marginBottom: 100 }}>
        <Container>
          <div style={{ textAlign: "left" }}>
            <Row>
              <Col md='12'>
                <h4>Registration is now closed.</h4>
              </Col>
              {/* <Col md='12'>
                <h4>Thank you for registering!</h4>
                <p>
                  <em>
                    **Registration is considered complete upon receipt of full
                    payment and subsequent confirmation via email from The Sol
                    Institute, LLC.
                  </em>
                </p>
                <br />
                <blockquote>
                  <p
                    class='blockquote blockquote-primary'
                    style={{ color: "black", borderColor: "#822EAF" }}
                  >
                    If paying by Debit/Credit card: <br />
                    <Button
                      color='info'
                      href='https://buy.stripe.com/4gw5nu0Zx7Oq5Lq7ss'
                      target='_blank'
                    >
                      Pay Now
                    </Button>
                    <br />
                    If paying by check, make check payable to:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      The Sol Institute, LLC
                    </span>
                    <br />
                    Send to: <br />
                    <span style={{ fontWeight: "bold" }}>
                      The Sol Institute, LLC
                      <br /> 680 Craig Rd. <br />
                      Ste 103 <br />
                      St. Louis, MO 63141
                    </span>
                  </p>
                </blockquote>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
      <SolFooter />
    </>
  );
}

export default Thanks;
