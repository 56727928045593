/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function SolHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className='page-header clear-filter' filter-color='blue'>
        <div
          className='page-header-image'
          style={{
            backgroundImage:
              "url(" + require("assets/img/IMG_9076.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className='content-center brand'>
            <img
              alt='...'
              className='n-logo'
              src={require("assets/img/sol_sun.png").default}
            ></img>
            <h1 className='h1-seo'>The Sol Institute</h1>
            <h3>Lighting The Way For Behavioral Health</h3>
            <h4>Individual, Couples &amp; Family Counseling</h4>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SolHeader;
