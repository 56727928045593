import React from "react";

import { Card, CardBody, Container, Row, Col } from "reactstrap";

// core components
import SolNavbar from "components/Navbars/SolNavbar.js";
import SolFooter from "components/Footers/SolFooter.js";

function About() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SolNavbar />
      <div style={{ color: "black", paddingTop: 100, marginBottom: 100 }}>
        <Container>
          <h3 style={{ color: "purple" }}>About</h3>
          <div style={{ textAlign: "center" }}>
            <img
              src={require("assets/img/AboutMe.jpg").default}
              style={{ maxWidth: 500 }}
            />
            <br />
            <br />
            <h4 style={{ margin: 0 }}>Agnes Jos Elking, PhD</h4>
            <h5>Licensed Professional Counselor</h5>

            <p>
              I believe people have a human need to be heard, valued, and
              validated. My approach means creating a safe, trusting, and
              accepting therapy environment. I have worked in outpatient,
              residential, and hospital settings to provide individual, couple,
              family, and group counseling. My experiences in these various
              settings allows me to tailor therapy to meet the needs of each
              individual, couple, or family.
            </p>
            <p>
              I am especially skilled in working with families impacted by
              substance use. I have completed Level 1 and Level 2 Training in
              Gottman Method Couples Therapy as well as the Level 3 Practicum
              Training in Gottman Methods Couples Therapy. I hold a Doctorate in
              Marriage and Family Therapy from Saint Louis University. I am a
              Licensed Professional Counselor and member of the American
              Counseling Association.
            </p>
          </div>
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <em>
              “There are two ways of spreading light: to be the candle or the
              mirror that reflects it.”
            </em>
            &nbsp;&nbsp;- Edith Wharton
          </div>
        </Container>
      </div>
      <SolFooter />
    </>
  );
}

export default About;
