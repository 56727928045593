import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Input,
  FormGroup,
  Button,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";

// core components
import SolNavbar from "components/Navbars/SolNavbar.js";
import SolFooter from "components/Footers/SolFooter.js";

function Events() {
  const history = useHistory();
  let pageHeader = React.createRef();

  const [registration, setRegistration] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    emailConfirm: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    birthYear: "",
    howdYouHear: "",
    dietary: "",
    goal: "",
    notes: "",
    payment: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const phoneRef = useRef();

  // const handleChange = () => {
  //   const cardValue = phoneRef.current.value
  //     .replace(/\D/g, "")
  //     .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
  //   phoneRef.current.value = !cardValue[2]
  //     ? cardValue[1]
  //     : `${cardValue[1]}-${cardValue[2]}${`${
  //         cardValue[3] ? `-${cardValue[3]}` : ""
  //       }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
  //   const numbers = phoneRef.current.value.replace(/(\D)/g, "");

  //   setRegistration({ ...registration, phone: cardValue });
  // };

  // useEffect(() => {
  //   handleChange();
  // }, [registration.phone]);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const sendEmail = () => {
    const serviceId = "service_8p923zj";
    const templateId = "template_stx4f88";
    const userId = "j5ciGXptHrzL_ldJc";

    sendFeedback(serviceId, templateId, userId, {
      eventName: "Unsettled",
      firstName: registration.firstName,
      lastName: registration.lastName,
      phone: registration.phone,
      email: registration.email,
      birthYear: registration.birthYear,
      address: registration.address,
      address2: registration.address2,
      city: registration.city,
      state: registration.state,
      zip: registration.zip,
      dietary: registration.dietary,
      howdYouHear: registration.howdYouHear,
      goal: registration.goal,
      notes: registration.notes,
      payment: registration.payment,
      from_name: `${registration.lastName}, ${registration.firstName}`,
      reply_to: registration.email,
    });
  };

  async function sendFeedback(serviceId, templateId, userId, emailData) {
    let data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: userId,
      template_params: emailData,
    };

    const response = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          history.push("/thanks");
        }
      })
      .then((data) => setAlert(true))
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const saveRegistration = () => {
    setSubmitting(true);
    if (checkValid()) sendEmail();
  };

  const checkValid = () => {
    if (
      registration.firstName !== "" &&
      registration.lastName !== "" &&
      registration.phone !== "" &&
      registration.email !== "" &&
      registration.birthYear !== "" &&
      registration.address !== "" &&
      registration.city !== "" &&
      registration.state !== "" &&
      registration.zip !== "" &&
      registration.payment !== "-- please select --" &&
      registration.payment !== ""
    )
      return true;
    else return false;
  };

  return (
    <>
      <SolNavbar />
      <div style={{ color: "black", paddingTop: 100, marginBottom: 100 }}>
        <Container>
          <div style={{ textAlign: "left" }}>
            <Row>
              <Col md='12'>
                <div>
                  <img
                    src={
                      require("assets/img/flyers/unsettled_banner.png").default
                    }
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                  <div style={{ float: "right" }}>
                    <Button
                      color='info'
                      href={
                        require("assets/img/flyers/unsettled_workshop.pdf")
                          .default
                      }
                      target='_blank'
                    >
                      Download Flyer
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <div>
                  <div style={{ float: "left" }}>
                    <h3 style={{ color: "purple" }}>
                      *** Registration is now closed. ***
                    </h3>
                  </div>
                  {/* <div style={{ float: "left", paddingTop: 5, marginLeft: 10 }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: 18,
                        fontStyle: "italic",
                      }}
                    >
                      Early-bird pricing has been extended!
                    </span>
                  </div> */}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col md='3'>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        firstName: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.firstName ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your first name.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='3'>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        lastName: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.lastName ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your last name.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='2'>
                <FormGroup>
                  <Label>Birth Year</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        birthYear: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.birthYear ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your birth year.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        phone: e.target.value,
                      })
                    }
                  ></Input>
                  {submitting && !registration.phone ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your phone number.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label>Email Address</Label>
                  <Input
                    defaultValue=''
                    type='email'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        email: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.email ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your email address.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup>
                  <Label>Confirm Email Address</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        emailConfirm: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting &&
                  registration.email !== registration.emailConfirm ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Email addresses don't match.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='8'>
                <FormGroup>
                  <Label>Address</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        address: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.address ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your address.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label>Address 2</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        address2: e.target.value,
                      })
                    }
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='6'>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        city: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.city ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your city.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='2'>
                <FormGroup>
                  <Label>State</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        state: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.state ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your state.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label>Zip Code</Label>
                  <Input
                    defaultValue=''
                    type='text'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        zip: e.target.value,
                      })
                    }
                    required
                  ></Input>
                  {submitting && !registration.zip ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your zip code.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label>Dietary Preferences or Restrictions?</Label>
                  <Input
                    defaultValue=''
                    type='textarea'
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "30px",
                    }}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        dietary: e.target.value,
                      })
                    }
                  ></Input>
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label>Goal or Purpose in Attending this Workshop?</Label>
                  <Input
                    defaultValue=''
                    type='textarea'
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "30px",
                    }}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        goal: e.target.value,
                      })
                    }
                  ></Input>
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup>
                  <Label>How’d You Hear About Us?</Label>
                  <Input
                    defaultValue=''
                    type='textarea'
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "30px",
                    }}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        howdYouHear: e.target.value,
                      })
                    }
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <FormGroup>
                  <Label for='exampleSelect'>Payment Method</Label>
                  <Input
                    id='exampleSelect'
                    name='select'
                    type='select'
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        payment: e.target.value,
                      })
                    }
                  >
                    <option>-- please select --</option>
                    <option>Debit/Credit Card</option>
                    <option>Check</option>
                  </Input>
                  {submitting &&
                  (registration.payment === "-- please select --" ||
                    registration.payment === "") ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter your method of payment.
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md='9'>
                <FormGroup>
                  <Label>Additional Notes?</Label>
                  <Input
                    defaultValue=''
                    type='textarea'
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "30px",
                    }}
                    onChange={(e) =>
                      setRegistration({
                        ...registration,
                        notes: e.target.value,
                      })
                    }
                    required
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
                <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                  **Registration is considered complete upon receipt of full
                  payment and subsequent confirmation via email from The Sol
                  Institute, LLC.
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button color='info' onClick={saveRegistration}>
                  Register
                </Button>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>
      <SolFooter />
    </>
  );
}

export default Events;
