import React from "react";

import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";

// core components
import SolNavbar from "components/Navbars/SolNavbar.js";
import SolFooter from "components/Footers/SolFooter.js";

function Services() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SolNavbar />
      <div style={{ color: "black", paddingTop: 100, marginBottom: 100 }}>
        <Container>
          <div style={{ textAlign: "left" }}>
            <h3 style={{ color: "purple" }}>Services</h3>
          </div>
        </Container>

        <Container>
          <Row>
            <Col md='4'>
              <Card>
                <CardHeader style={{ textAlign: "center" }}>
                  <h4>Individual Counseling</h4>
                </CardHeader>
                <CardBody>
                  <p style={{ marginBottom: 125 }}>
                    Individual counseling is a personal opportunity to receive
                    support and experience growth during challenging times in
                    life. Individual counseling can help one deal with many
                    personal topics in life such as anger, depression, anxiety,
                    substance abuse, marriage and relationship challenges,
                    parenting problems, school difficulties, career changes etc.
                  </p>
                  <span style={{ fontSize: 12 }}>
                    * Source American Counseling Association
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card>
                <CardHeader style={{ textAlign: "center" }}>
                  <h4>Couples Counseling</h4>
                </CardHeader>
                <CardBody>
                  <p style={{ marginBottom: 100 }}>
                    Every couple experiences ups and downs in their levels of
                    closeness and harmony over time. This can range from basic
                    concerns of stagnation to serious expressions of aggressive
                    behavior. Marriage counseling or couples counseling can help
                    resolve conflicts and heal wounds. Overall, couples
                    counseling can help couples slow down their spiral and
                    reestablish realistic expectations and goals.
                  </p>
                  <span style={{ fontSize: 12 }}>
                    * Source American Counseling Association
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card>
                <CardHeader style={{ textAlign: "center" }}>
                  <h4>Family Counseling</h4>
                </CardHeader>
                <CardBody>
                  <p>
                    Family counseling is often sought due to a life change or
                    stress negatively affecting one or all areas of family
                    closeness, family structure (rules and roles) or
                    communication style. This mode of counseling can take a
                    variety of forms. Sometimes it is best to see an entire
                    family together for several sessions. Common issues
                    addressed in family counseling are concerns around
                    parenting, sibling conflict, loss of family members, new
                    members entering the family, dealing with a major move or a
                    general change affecting the family system.
                  </p>
                  <span style={{ fontSize: 12 }}>
                    * Source American Counseling Association
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md='6' style={{ textAlign: "center" }}>
              <h5>In Person</h5>
              <img src={require("assets/img/InPerson.jpg").default} />
              <br />
              <br />
            </Col>
            <Col md='6' style={{ textAlign: "center" }}>
              <h5>Telehealth</h5>
              <img src={require("assets/img/Telehealth.jpg").default} />
              <br />
              <br />
            </Col>
          </Row>
          <br />
        </Container>
        <Container>
          <Card>
            <CardHeader style={{ textAlign: "center" }}>
              <h4>Community</h4>
            </CardHeader>
            <CardBody>
              <p>
                In addition to counseling services, I have experience with
                community outreach and education. This includes but is not
                limited to presentations to other behavioral health
                professionals, law enforcement, students, as well the general
                public.
              </p>
            </CardBody>
          </Card>
        </Container>
      </div>
      <SolFooter />
    </>
  );
}

export default Services;
