import React from "react";
import { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";

import {
  Input,
  FormGroup,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
} from "reactstrap";

// core components
import SolNavbar from "components/Navbars/SolNavbar.js";
import SolFooter from "components/Footers/SolFooter.js";

function Contact() {
  let pageHeader = React.createRef();

  const [emailName, setEmailName] = useState("");
  const [emailNameExists, setEmailNameExists] = useState(true);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressExists, setEmailAddressExists] = useState(true);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailMessageExists, setEmailMessageExists] = useState(true);
  const [alert, setAlert] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const sendEmail = () => {
    const serviceId = "service_8p923zj";
    const templateId = "template_rjjqv1j";
    const userId = "j5ciGXptHrzL_ldJc";

    // if (recaptchaToken !== null) {
    if (emailName !== "" && emailAddress !== "" && emailMessage !== "") {
      setEmailNameExists(true);
      setEmailAddressExists(true);
      setEmailMessageExists(true);

      sendFeedback(serviceId, templateId, userId, {
        message: emailMessage,
        from_name: emailName,
        reply_to: emailAddress,
      });
    } else {
      if (emailName === "") setEmailNameExists(false);
      if (emailAddress === "") setEmailAddressExists(false);
      if (emailMessage === "") setEmailMessageExists(false);
    }
    // } else {
    //   setRecaptchaAlert(true);
    // }
  };

  async function sendFeedback(serviceId, templateId, userId, emailData) {
    let data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: userId,
      template_params: emailData,
    };

    const response = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      }
    )
      .then((response) => console.log("response", response))
      .then((data) => setAlert(true))
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // const onChange = (value) => {
  //   setRecaptchaToken(value);
  //   setRecaptchaAlert(false);
  // };

  return (
    <>
      <SolNavbar />
      <div style={{ color: "black", paddingTop: 100, marginBottom: 100 }}>
        <Container>
          <div style={{ textAlign: "left" }}>
            <h3 style={{ color: "purple" }}>Contact</h3>
            <Alert color='info' isOpen={alert}>
              <Container>
                <div className='alert-icon'>
                  <i className='now-ui-icons ui-2_like'></i>
                </div>
                <strong>Thank you!</strong> We have received your message and
                will respond at our soonest availability.
                <button
                  type='button'
                  className='close'
                  onClick={() => setAlert(false)}
                >
                  <span aria-hidden='true'>
                    <i className='now-ui-icons ui-1_simple-remove'></i>
                  </span>
                </button>
              </Container>
            </Alert>
            <Alert color='danger' isOpen={recaptchaAlert}>
              <Container>
                <div className='alert-icon'>
                  <i className='now-ui-icons ui-1_simple-remove'></i>
                </div>
                Please verify that you are not a robot.
                <button
                  type='button'
                  className='close'
                  onClick={() => setAlert(false)}
                >
                  <span aria-hidden='true'>
                    <i className='now-ui-icons ui-1_simple-remove'></i>
                  </span>
                </button>
              </Container>
            </Alert>
            <Row>
              <Col md='6'>
                <FormGroup>
                  {!emailNameExists ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter a Name.
                    </div>
                  ) : null}
                  <Input
                    defaultValue=''
                    placeholder='Name'
                    type='text'
                    onChange={(e) => setEmailName(e.target.value)}
                    required
                  ></Input>
                  <br />
                  {!emailAddressExists ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      * Please enter an email address.
                    </div>
                  ) : null}
                  <Input
                    defaultValue=''
                    placeholder='Email'
                    type='text'
                    inputMode='email'
                    onChange={(e) => setEmailAddress(e.target.value)}
                    required
                  ></Input>
                  <br />
                  {!emailMessageExists ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      * Please enter a Message.
                    </div>
                  ) : null}
                  <Input
                    defaultValue=''
                    placeholder='Message'
                    type='textarea'
                    style={{
                      border: "1px solid #dedede",
                      WebkitBorderRadius: 15,
                      MozBorderRadius: 15,
                      borderRadius: 15,
                      height: 200,
                    }}
                    onChange={(e) => setEmailMessage(e.target.value)}
                    required
                  ></Input>
                  <br />
                  {/* <ReCAPTCHA
                    sitekey='6Lfy3TohAAAAADBwScDF4LX8VvqeDiVZjXIM256s'
                    onChange={onChange}
                  />
                  <br /> */}
                  <Button color='info' onClick={sendEmail}>
                    Send
                  </Button>
                </FormGroup>
              </Col>
              <Col md='6'>
                <h4 style={{ marginTop: 0 }}>The Sol Institute</h4>
                <div style={{ fontSize: 16, marginBottom: 20 }}>
                  680 Craig Rd.
                  <br />
                  Ste 103
                  <br />
                  St. Louis, MO 63141
                  <br />
                  <br />
                  <a href='tel:3144725527' style={{ color: "#822EAF" }}>
                    314-472-5527
                  </a>{" "}
                  (P)
                  <br />
                  <a
                    href='mailto:agnes@thesolinstitute.org'
                    style={{ color: "#822EAF" }}
                  >
                    agnes@thesolinstitute.org
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.1411036883637!2d-90.4407795673373!3d38.668625102782286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df32bc1c894a99%3A0x198d6691a368c31e!2s680%20Craig%20Rd%2C%20St.%20Louis%2C%20MO%2063141!5e0!3m2!1sen!2sus!4v1679938498252!5m2!1sen!2sus'
                  width='100%'
                  height='450'
                  style={{ border: 0 }}
                  allowfullscreen=''
                  loading='lazy'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <SolFooter />
    </>
  );
}

export default Contact;
